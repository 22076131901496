import React from "react";
import { t } from "i18next";

const MatchReplay = () => {
  return (
    <div>
      {t(
        "cs2:match.replay.empty",
        "Replay is not available yet for this match. Please check back later.",
      )}
    </div>
  );
};

export default MatchReplay;
